<template>  
  <div>  
  
  <v-card
    class="mx-auto"
    dense
    
  >
    
    <v-list-item v-for="(item, $index) in list" :key="$index">
      <v-row class="mx-1 my-1 py-1">      
        <v-col class="px-1 py-1"
              cols="9"                   
            >            
          <v-list-item-content>
            <v-list-item-title>{{item.name}}</v-list-item-title>
            <v-list-item-subtitle v-if="item.options_text">
              {{item.options_text}}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.qty>1">
              {{item.price}} × {{item.qty}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-col>
        
        <v-col class="px-1 py-1"
              cols="3"                   
            >
          <v-list-item-content>
            <v-list-item-title>{{item.total}}</v-list-item-title>
          </v-list-item-content>
        </v-col>
      </v-row>
    </v-list-item>

  </v-card>
		
  
  
  <v-footer app bottom fixed v-if="list.length">
    <v-col      
      cols="6"
    >
      <v-btn class="green white--text" @click="confirmClick">{{langpack.confirm}}</v-btn>
    </v-col>
    
    <v-col class="text-right"
      cols="6"
    >
      <span class="amount">{{amount}}</span>
    </v-col>
    
  </v-footer>
  
  
  </div>
</template>

<script>
  import DataProvider from '../modules/DataProvider.js';
  import UserData from '../modules/UserData.js';    
  
  export default {
    name: 'ShopCheckout',        
    data ()
    {
      return {        
        title: 'Bestätigung',
        langpack: {},
        list: [],
        amount: 0
      }
    },   
    methods: 
    {
      loadData()
      {
        var customer_token = UserData.getShopToken();
        if( customer_token )
        {
          var args = 
          {
            handler: 'order',
            subprog: 'view',
            customer_token: customer_token
          }
          
          DataProvider.post( args ).then(  ret => {                                                    
                                                    if( ret.data )
                                                    {
                                                      this.langpack = ret.data.langpack;
                                                      this.list = ret.data.order.table;
                                                      this.amount = ret.data.order.amount;
                                                      this.$emit('cart-changed', this.list.length);
                                                    }                                                                                                    
                                                  },
                                        error => { 
                                                    console.error( JSON.stringify( error ) );                                                    
                                                  }
                                        );
        }
      },
      confirmClick()
      {
        var customer_token = UserData.getShopToken();
        if( customer_token )
        {
          var args = 
          {
            handler: 'order', 
            subprog: 'confirm',
            customer_token: customer_token
          }
          
          DataProvider.post( args ).then(  ret => {                                                    
                                                    if( ret.data )
                                                    {
                                                      this.list = [];
                                                      this.amount = "";
                                                      this.$emit('cart-changed', 0);
                                                      this.$emit('orders-changed', ret.data.length);
                                                      this.$router.replace({ path: '/shop/deliver' });
                                                      
                                                      if( ret.data.message )
                                                      {
                                                        this.$toast.open({
                                                          message: ret.data.message.text,
                                                          position: 'bottom',
                                                          type: ret.data.message.variant       
                                                        });
                                                      }                                                    
                                                    }                                                                                                    
                                                  },
                                        error => { 
                                                    console.error( JSON.stringify( error ) );                                                    
                                                  }
                                        );
        }
      }
    },
    created: function()
    {
      if( UserData.getIsLoggedIn() )
      {
        this.$emit('subpage-loaded', this.title);
        this.loadData();
      }
      else
      {
        this.$router.push({ path: '/login' });
      }
    }     
  }
</script>

<style scoped>
  
  .small
  {
    font-size: 1em;
    float: none;
  }
  
  .basket_empty
  {
    text-align: center;
    padding: 2rem 0;
  }
  
  .form_field
  {
    
  }
  
  .price
  {
    font-size: 0.95em;
  }
  
  .total
  {
    font-weight: bold;
  }
  
  .amount
  {
    font-size: 1.05em;
    font-weight: bold;
  }
  
  .form_field >>> .v-input__control
  {
    min-height: 26px !important;
  }
  
  .form_field >>> .v-input__slot
  {
    min-height: 26px !important;
    padding: 0 !important;
    margin-bottom: 0 !important;
  }
  
  .form_field >>> .v-text-field__slot
  {
    /*padding: 0 !important;*/
    
  }

  .form_field >>> .v-text-field__slot input {
    color: #555;
    text-align: center;
    padding: 0 !important;
    font-size: 0.8em;
  }
  
  .form_field >>> .v-input__prepend-outer
  {
    margin-top: 1px !important;
  }
  
  .form_field >>> .v-input__append-outer
  {
    margin-top: 1px !important;
  }
  
  >>>.v-input__icon.v-input__icon--prepend .v-icon
  {
    border: 1px solid #fff;
    line-height: 18px;
    font-size: 18px;
    /*margin-bottom: 12px;*/
  }
    
  >>>.v-input__icon.v-input__icon--prepend .v-icon:before
  {
    color: #f00;
  }
  
  >>>.v-input__icon.v-input__icon--append-outer
  {
    margin-bottom: 0 !important;
  }
    
  >>>.v-input__icon.v-input__icon--append-outer .v-icon
  {
    border: 1px solid #fff;
    line-height: 18px;
    font-size: 18px;
    /*margin-bottom: 12px;*/
  }
  
  >>>.v-input__icon.v-input__icon--append-outer .v-icon:before
  {
    color: #f00;
  }
      
  .swipeout-action {
    display: flex;
    align-items: center;
    padding: 0 3rem;
    cursor: pointer;
    left: 0;
  }
    
  .swipeout-action.red {
      color: white;
      background-color: rgb(255, 59, 48);
  }
  .swipeout-action.red:hover {
      background-color: darken(rgb(255, 59, 48), 5%);
  }
  
  .swipeout-list-item 
  {
    flex: 1;
    border-bottom: 1px solid lightgray;
  }
  
  .swipeout-list-item:last-of-type 
  {
    border-bottom: none;
  }
  
  .card {
    width: 100%;    
    box-shadow: none;    
  }
  
  .card-content {
    padding: 0;
    border-radius: 3px;
  }
  
  .v-image
  {
    border: #fff 1px solid;
  }
  
  .transition-right {
    transform: translate3d(100%, 0 ,0) !important;
  }
  .transition-left {
    transform: translate3d(-100%, 0 ,0) !important;
  }
  
</style>