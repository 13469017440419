<template>  
  <div>
  
  <swipe-list
			ref="list"
			class="card"			
			:items="list"
			item-key="id"			
		>    
    <template v-slot="{ item }">
      <div ref="content" class="card-content">        
        <v-row class="mx-1 py-2">        
          <v-col class="px-1"
            cols="4"          
            sm="3"
            md="2"
            lg="1"        
          >      
            <v-img height="90" :src="item.picture" contain></v-img>            
          </v-col>          
          
          <v-col class="px-1"
            cols="8"          
            sm="9"
            md="10"
            lg="11"        
          >    
            <!--
            <v-row class="mx-1">
              <v-card-text class="px-0 py-2">
              <v-btn class="btn_remove" icon><v-icon>mdi-close</v-icon></v-btn>
              <h3>{{item.name}}</h3>
              <label class="small">{{item.options_text}}</label>
              </v-card-text> 
            </v-row>
            -->
            
            <v-row class="mx-0 my-0">              
              <v-col class="px-1 pt-1 pb-0"
                  cols="6"          
                  sm="3"
                  md="2"
                  lg="1"        
                >          
                
                <v-text-field
                    v-model.number="item.qty"
                    append-outer-icon="mdi-plus" 
                    prepend-icon="mdi-minus"
                    @click:append-outer="incrementQty(item)"
                    @click:prepend="decrementQty(item)"
                    single-line
                    solo                    
                    dense 
                    flat
                    :readonly=true
                    :hide-details=true
                    class="form_field"                
                    background-color="grey lighten-2"
                  >
                </v-text-field>
              </v-col>

              <v-col class="px-1 pt-1 pb-0"
                  cols="6"          
                  sm="6"
                  md="8"
                  lg="9"        
                >
                  <span class="price">× {{item.price}}</span>
              </v-col>
              
              <v-col class="px-1 pt-0 pb-0 text-right"
                  cols="12"          
                  sm="3"
                  md="2"
                  lg="2"
                >
                  <span class="price total">= {{item.total}}</span>
              </v-col>              
            </v-row>            
          </v-col>
        </v-row>        
      </div>
    </template>
    
    <template v-slot:right="{ item }">
      <div class="swipeout-action red" @click="removeItem(item)">
        <v-icon>mdi-delete-forever</v-icon>
      </div>				
    </template>
    
  </swipe-list>
		
  <template>
    <div class="basket_empty" v-if="list.length==0">					
      {{langpack.basket_empty}}
    </div>
  </template>
  
  <v-footer app bottom fixed v-if="list.length">
    <v-col      
      cols="6"
    >
      <v-btn class="yellow black--text" @click="checkoutClick">{{loggedIn ? langpack.checkout : langpack.login}}</v-btn>
    </v-col>    
    <v-col class="text-right"
      cols="6"
    >
      <span class="amount">{{amount}}</span>
    </v-col>    
    <div v-if="!loggedIn" class="small_label text-center"><v-icon>mdi-alert</v-icon>{{langpack.login_required}}</div>    
  </v-footer>
  
  </div>
</template>

<script>
  import DataProvider from '../modules/DataProvider.js';
  import UserData from '../modules/UserData.js';
  import { SwipeList } from 'vue-swipe-actions';
  import 'vue-swipe-actions/dist/vue-swipe-actions.css';
  
  export default {
    name: 'ShopBasket',
    
    components: {    
    SwipeList
    },
    data ()
    {
      return {        
        title: 'Warenkorb',
        langpack: {},
        list: [],
        amount: 0,
        loggedIn: UserData.getIsLoggedIn()
      }
    },   
    methods: 
    {
      checkoutClick()
      {
        if( this.loggedIn )
        {          
          this.$router.replace({ path: '/shop/checkout' });          
        }
        else
        {
          this.$router.push({ path: '/login' });
        }
      },
      loadData()
      {
        var customer_token = UserData.getShopToken();
        if( customer_token )
        {
          var args = 
          {
            handler: 'order', 
            subprog: 'view',
            customer_token: customer_token            
          }
          
          DataProvider.post( args ).then(  ret => {
                                                    if( ret.data )
                                                    {
                                                      this.langpack = ret.data.langpack;
                                                      this.list = ret.data.order.table;
                                                      this.amount = ret.data.order.amount;
                                                      this.$emit('cart-changed', this.list.length);
                                                    }                                                                                                    
                                                  },
                                        error => { 
                                                    console.error( JSON.stringify( error ) );                                                    
                                                  }
                                        );
        }
      },     
      removeItem( item )
      {
        var customer_token = UserData.getShopToken();
        if( customer_token )
        {
          var args = 
          {
            handler: 'order', 
            subprog: 'item',
            command: 'remove',
            id: item.id,
            customer_token: customer_token            
          }          
          DataProvider.post( args ).then(  ret => {                                                    
                                                    if( ret.data )
                                                    {                                                      
                                                      this.list = ret.data.order.table;
                                                      this.amount = ret.data.order.amount;
                                                      this.$emit('cart-changed', this.list.length);
                                                    }
                                                  },
                                        error => { console.error( JSON.stringify( error ) ); } );
        }
      },
      incrementQty( item )
      {
        var customer_token = UserData.getShopToken();
        if( customer_token )
        {
          var args = 
          {
            handler: 'order', 
            subprog: 'item',
            command: 'increment_qty',
            id: item.id,
            customer_token: customer_token            
          }
          DataProvider.post( args ).then(  ret => {                                                    
                                                    if( ret.data )
                                                    {
                                                      this.list = ret.data.order.table;
                                                      this.amount = ret.data.order.amount;
                                                    }
                                                  },
                                        error => { console.error( JSON.stringify( error ) ); } );
        }
      },
      decrementQty( item )
      {
        if( item.qty > 1 )
        {
          var customer_token = UserData.getShopToken();
          if( customer_token )
          {
            var args = 
            {
              handler: 'order', 
              subprog: 'item',
              command: 'decrement_qty',
              id: item.id,
              customer_token: customer_token            
            }            
            DataProvider.post( args ).then(  ret => {
                                                      if( ret.data )
                                                      {                                                      
                                                        this.list = ret.data.order.table;
                                                        this.amount = ret.data.order.amount;
                                                      }                                                                                                    
                                                    },
                                          error => { console.error( JSON.stringify( error ) ); } );
          }
        }
        else
        {
          this.$toast.open({
            message: this.langpack.qty_must_be_qt_1,
            position: 'bottom',
            type: 'warning'
          });
        }
      }
    },
    created: function()
    {
      this.$emit('subpage-loaded', this.title);
      this.loadData();
    }     
  }
</script>

<style scoped>
    
  .small
  {
    font-size: .9em;
    float: none;
    font-style: italic;
  }
  
  .small_label
  {
    font-size: 0.8em;    
  }
  
  .basket_empty
  {
    text-align: center;
    padding: 2rem 0;
  }
  
  .form_field
  {
    
  }
  
  .price
  {
    font-size: 0.95em;
  }
  
  .total
  {
    font-weight: bold;
  }
  
  .amount
  {
    font-size: 1.05em;
    font-weight: bold;
  }
  
  .form_field >>> .v-input__control
  {
    min-height: 26px !important;
  }
  
  .form_field >>> .v-input__slot
  {
    min-height: 26px !important;
    padding: 0 !important;
    margin-bottom: 0 !important;
  }
  
  .form_field >>> .v-text-field__slot
  {
    /*padding: 0 !important;*/
    
  }

  .form_field >>> .v-text-field__slot input {
    color: #555;
    text-align: center;
    padding: 0 !important;
    font-size: 0.8em;
  }
  
  .form_field >>> .v-input__prepend-outer
  {
    margin-top: 1px !important;
  }
  
  .form_field >>> .v-input__append-outer
  {
    margin-top: 1px !important;
  }
  
  >>>.v-input__icon.v-input__icon--prepend .v-icon
  {
    border: 1px solid #fff;
    line-height: 20px;
    font-size: 20px;    
  }
    
  >>>.v-input__icon.v-input__icon--prepend .v-icon:before
  {
    color: #f00;
  }
  
  >>>.v-input__icon.v-input__icon--append-outer
  {
    margin-bottom: 0 !important;
  }
    
  >>>.v-input__icon.v-input__icon--append-outer .v-icon
  {
    border: 1px solid #fff;
    line-height: 20px;
    font-size: 20px;    
  }
  
  >>>.v-input__icon.v-input__icon--append-outer .v-icon:before
  {
    color: #f00;
  }
      
  .swipeout-action {
    display: flex;
    align-items: center;
    padding: 0 3rem;
    cursor: pointer;
    left: 0;
  }
    
  .swipeout-action.red {
      color: white;
      background-color: rgb(255, 59, 48);
  }
  .swipeout-action.red:hover {
      background-color: darken(rgb(255, 59, 48), 5%);
  }
  
  .swipeout-list-item 
  {
    flex: 1;
    border-bottom: 1px solid lightgray;
  }
  
  .swipeout-list-item:last-of-type 
  {
    border-bottom: none;
  }
  
  .card {
    width: 100%;    
    box-shadow: none;    
  }
  
  .card-content {
    padding: 0;
    border-radius: 3px;
    user-select: none;
  }
  
  .v-image
  {
    border: #fff 1px solid;
  }
  
  .transition-right {
    transform: translate3d(100%, 0 ,0) !important;
  }
  .transition-left {
    transform: translate3d(-100%, 0 ,0) !important;
  }
  
  .btn_remove
  {
    float: right;
  }
  
  .btn_remove.v-btn--icon.v-size--default .v-icon
  {
    border: 1px solid;
    border-radius: 0;
    width: 24px;
    height: 24px;
    font-size: 18px;    
  }
  
</style>