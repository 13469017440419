<template>
  
  <v-sheet dark>
  
    <v-app-bar dark fixed app>
      <router-link class="btn_back"
          v-if="$routerHistory.hasPrevious()"
          :to="{ path: $routerHistory.previous().path }">
          <v-icon>mdi-arrow-left</v-icon>
      </router-link>
      <v-spacer></v-spacer>
      <v-toolbar-title>{{title}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click.stop="drawer = !drawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>
    
    
    <v-navigation-drawer
      v-model="drawer"
      absolute
      dark      
      temporary      
    >
      <v-list-item>
        <!--
        <v-list-item-avatar>
          <v-img v-bind:src="user.avatar"></v-img>
        </v-list-item-avatar>
        -->
        <v-list-item-content>
          <v-list-item-title>{{user.fullname}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>      
        
        <v-list-item          
          link
          @click="gotoHome()"
        >
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{langpack.home}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        
        <v-list-item          
          v-if="loggedIn"
          link
          to="/register"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-edit</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{langpack.profile}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        
        <v-list-item          
          v-if="!loggedIn"
          link
          to="/register"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-edit</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{langpack.register}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        
        <v-list-item          
          v-if="!loggedIn"
          link
          to="/login"
        >
          <v-list-item-icon>
            <v-icon>mdi-location-enter</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{langpack.login}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        
        <v-list-item          
          v-if="loggedIn"
          link
          @click="logout()"
        >
          <v-list-item-icon>
            <v-icon>mdi-location-exit</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{langpack.logout}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        
      </v-list>
    </v-navigation-drawer>
        
    <router-view @subpage-loaded="setTitle($event)" @cart-changed="setCartBadge($event)" @orders-changed="setOrdersBadge($event)" />
    
    <v-bottom-navigation app
      v-model="activeBtn"
      color="white"
      dark
      fixed
      active-class="active_tab"
    >
      <v-btn to="/shop/catalog">
        <span>{{group1}}</span>        
        <v-icon>mdi-tshirt-crew-outline</v-icon>
      </v-btn>
      
      <v-btn to="/shop/special">
        <span>{{group2}}</span>
        <v-icon>mdi-gift-outline</v-icon>
      </v-btn>
      
      <v-btn to="/shop/basket">
        <span>{{langpack.shopping_cart}}</span>
        <v-badge :value="cartCount" :content="cartCount" color="red" overlap>
          <v-icon>mdi-cart-outline</v-icon>
        </v-badge>
      </v-btn>

      <v-btn to="/shop/deliver">
        <span>{{langpack.order_status}}</span>
        <v-badge :value="ordersCount" :content="ordersCount" color="red" overlap>
          <v-icon>mdi-check-circle-outline</v-icon>
        </v-badge>
      </v-btn>
      
    </v-bottom-navigation> 
  </v-sheet>  
</template>

<script>
  import Vue from 'vue';
  import UserData from '../modules/UserData.js';
  import DataProvider from '../modules/DataProvider.js';
  
  export default {
    name: 'ShopMain',
    metaInfo()
    {
      return { title: this.title }      
    },
    data () {
      return {
        drawer: null,
        title: "",
        group1: "",
        group2: "",
        activeBtn: 0,
        cartCount: 0,
        ordersCount: 0,
        langpack: {},
        user: {},
        loggedIn: UserData.getIsLoggedIn()
      }
    },
    methods: {
                setTitle: function (new_title) 
                {
                  this.title = new_title;
                },
                gotoHome: function()
                {                  
                  if( Vue.config.auth_required )
                  {
                    if( UserData.getIsLoggedIn() )
                    {
                      this.$router.push({ path: '/shop/catalog' });
                    }
                    else
                    {        
                      this.$router.push({ path: '/login' });
                    }
                  }
                  else
                  {
                    this.$router.push({ path: '/shop/catalog' });
                  }
                },                
                loadScreen: function()
                {
                  var args = 
                  {
                    handler: 'shop',
                    subprog: 'shop_main',
                    command: 'view'
                  }
                  
                  var customer_token = UserData.getShopToken();
                  if( customer_token )
                  {
                    args.customer_token = customer_token;
                  }
                  
                  DataProvider.post( args ).then(  ret => {
                                                            if( ret && ret.data )
                                                            {                                                              
                                                              this.langpack = ret.data.langpack;
                                                              this.user = ret.data.user;
                                                              let topGroups = ret.data.topGroups;
                                                              this.group1 = topGroups.group1 ? topGroups.group1.name : "Group 1";
                                                              this.group2 = topGroups.group2 ? topGroups.group2.name : "Group 2";
                                                              
                                                              if( ret.data.countCart )
                                                              {
                                                                this.setCartBadge( ret.data.countCart );
                                                              }
                                                              
                                                              if( ret.data.customer_token )
                                                              {
                                                                UserData.setShopToken( ret.data.customer_token );
                                                              }
                                                            }
                                                          },
                                                  error => { 
                                                              console.error( JSON.stringify( error ) );
                                                            }
                                                );
                },
                logout: function()
                {
                  var args = 
                  {
                    handler: 'user', 
                    subprog: 'login', 
                    command: 'logout',
                    avoid401Handling: true
                  }
                  
                  DataProvider.post( args ).then(  ret => {
                                                            if( ret && ret.data )
                                                            {                                                              
                                                              setTimeout( () => {
                                                                                  UserData.logOut();
                                                                                  this.gotoHome(); 
                                                                                }, 1 );
                                                            }
                                                          },
                                                    error => {
                                                                console.error( JSON.stringify( error ) );
                                                              }
                                                );                
                },
                setCartBadge: function(value)
                {
                  this.cartCount = value > 0 ? value : "";                  
                },
                setOrdersBadge: function(value)
                {
                  this.ordersCount = value > 0 ? value : "";                  
                },
                updateCartBadge: function()
                {                
                  var customer_token = UserData.getShopToken();
                  if( customer_token )
                  {
                    var args = 
                    {
                      handler: 'order', 
                      subprog: 'count',
                      customer_token: customer_token,
                      avoid401Handling: true
                    }
                    
                    DataProvider.post( args ).then(  ret => {                                                            
                                                              if( ret && typeof(ret.data) != 'undefined' )
                                                              {
                                                                this.setCartBadge( ret.data );
                                                              }
                                                            },
                                                      error => {
                                                                  console.error( JSON.stringify( error ) );
                                                                }
                                                  );
                  }
                  else
                  {
                    this.setCartBadge( 0 );
                  } 
                },
                updateOrdersBadge: function()
                {                  
                  if( UserData.getIsLoggedIn() )
                  {
                    var args = 
                    {
                      handler: 'order', 
                      subprog: 'count_orders',                      
                      avoid401Handling: true
                    }
                    
                    DataProvider.post( args ).then(  ret => {                                                            
                                                              if( ret && typeof(ret.data) != 'undefined' )
                                                              {
                                                                this.setOrdersBadge( ret.data );
                                                              }
                                                            },
                                                      error => {
                                                                  console.error( JSON.stringify( error ) );
                                                                }
                                                  );
                  }
                  else
                  {
                    this.setOrdersBadge( 0 );
                  } 
                }
    },
    created: function()
    {
      this.loadScreen();
      this.updateCartBadge();
      this.updateOrdersBadge();
    }
    
  }
</script>

<style scoped>
  .active_tab
  {
    border-bottom: #f00 3px solid;
  }
  
  .btn_back
  {
    text-decoration: none;
  }
  
</style>