<template>    
  
  <div id="restore-password">    
    
    <v-app-bar dark fixed app>
      <router-link class="btn_back"
          v-if="$routerHistory.hasPrevious()"
          :to="{ path: $routerHistory.previous().path }">
          <v-icon>mdi-arrow-left</v-icon>
      </router-link>
      <v-spacer></v-spacer>
      <v-toolbar-title>{{langpack.label_title}}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    
    <v-form>    
      <v-container>      
        <v-row justify="center">          
          <v-col cols="12" sm="6" class="text-center">
            <label>{{langpack.label_restoring}}:</label>
            <v-text-field
              v-model.trim="email"
              v-bind:label=langpack.label_email_placeholder              
              outlined
              rounded-0
              single-line
              dense
              class="form_field"      
              color="red"
              v-bind:hint=langpack.label_invite
              persistent-hint
              background-color="grey lighten-2"
            >            
            </v-text-field>            
          </v-col>
                    
        </v-row>

        <v-row justify="center">
          <v-col class="text-center">
            <v-btn @click="clickSubmit()" tile outlined large color="red">{{langpack.label_send}}</v-btn>
          </v-col>
        </v-row>        
      </v-container>      
    </v-form>    
  </div>
</template>

<script>
  
  import DataProvider from '../modules/DataProvider.js'
  
  export default {
    name: 'RestorePassword',
    metaInfo()
    {
      return { title: this.langpack.label_title }      
    },
    components: {},
    data()
    {
      return {        
        email: "",
        langpack: {}
      }
    },
    methods:
    {
      loadScreen: function()
      {
        var args = 
        {
          handler: 'user', 
          subprog: 'restore', 
          command: 'screen',          
          avoid401Handling: true
        }
        
        DataProvider.post( args ).then(  ret => {                                                  
                                                  if( ret && ret )
                                                  {                                                    
                                                    this.langpack = ret.data;                                                    
                                                  }
                                                },
                                      error => { 
                                                  console.error( JSON.stringify( error ) );
                                                }
                                      );
      },
      clickSubmit: function()
      { 
        if( this.validateForm() )
        {
          this.submitData();
        }
      },
      submitData: function()
      {
        var args = 
        {
          handler: 'user', 
          subprog: 'restore', 
          command: 'proceed',
          email: this.email,          
          avoid401Handling: true
        }
        
        DataProvider.post( args ).then(  ret => {
                                                  if( ret && ret.data )
                                                  {
                                                    if( ret.data.message )
                                                    {
                                                      this.$toast.open({
                                                        message: ret.data.message,
                                                        position: 'bottom',
                                                        type: ret.data.variant == 'success' ? 'success' : 'warning'            
                                                      });
                                                      
                                                      if( ret.data.variant == 'success' )
                                                      {
                                                        setTimeout( () => { this.$router.push({ path: '/login' }).catch((err) => {console.error(err)}); }, 3000 );
                                                      }
                                                    }
                                                  }
                                                },
                                      error => { 
                                                  console.error( JSON.stringify( error ) );
                                                }
                                      );
      },
      validateForm: function()
      {
        if( !this.email.length )
        {
          this.$toast.open({
            message: this.langpack.message_empty_email,
            position: 'bottom',
            type: 'warning'            
          });
          return false;
        }
        
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if( !re.test( this.email ) )
        {
          this.$toast.open({
            message: this.langpack.message_invalid_email,
            position: 'bottom',
            type: 'warning'            
          }); 
          return false;
        }        
        return true;
      }
    },
    mounted: function()
    {
      this.loadScreen();
    }
  }
  
</script>

<style scoped>
  
  .btn_back
  {
    text-decoration: none;
  }
  
  #restore-password
  {
    margin-top: 20vh;
    background: #000;
  }
  
  .form_field >>> .v-messages__message
  {
    color: #aaa;
    text-align: center;
  }
  
  
  
  
  #restore-password button
  {
    border-radius: 0;
    text-transform: uppercase;
    color: #cc0f13;
    font-weight: bold;
    font-size: 1.2em;
    border-color: #fff;
    background: #000;
  }
  
  
  
  
  
</style>