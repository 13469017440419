import Vue from 'vue'
import VueRouter from 'vue-router'
import VueRouterBackButton from 'vue-router-back-button'

import LoginForm from '../components/LoginForm.vue'
import ShopMain from '../components/ShopMain.vue'
import ShopCatalog from '../components/ShopCatalog.vue'
import ShopSpecial from '../components/ShopSpecial.vue'
import ShopBasket from '../components/ShopBasket.vue'
import ShopCheckout from '../components/ShopCheckout.vue'
import ShopDeliver from '../components/ShopDeliver.vue'
import ShopProduct from '../components/ShopProduct.vue'
import RegisterUser from '../components/RegisterUser.vue'
import RestorePassword from '../components/RestorePassword.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: 'shop/catalog' },
  { path: '/login', component: LoginForm },
  { path: '/login/:confirm', component: LoginForm, props: true },
  { path: '/shop', component: ShopMain, 
    children: [
                {
                  path: 'catalog',
                  component: ShopCatalog
                },
                {
                  path: 'special',
                  component: ShopSpecial
                },
                {
                  path: 'basket',
                  component: ShopBasket
                },
                {
                  path: 'checkout',
                  component: ShopCheckout
                },
                {
                  path: 'deliver',
                  component: ShopDeliver
                },
                {
                  path: 'product/:id',
                  component: ShopProduct,
                  props: true
                }
              ] 
  },
  { path: '/register', component: RegisterUser },
  { path: '/restore', component: RestorePassword }
]

const router = new VueRouter({
  routes
})

Vue.use(VueRouterBackButton, { router })

export default router