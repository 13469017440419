import Vue from 'vue'
import App from './App.vue'
import Vuetify from 'vuetify'
import vuetify from './plugins/vuetify';

import VueToast from 'vue-toast-notification';

import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMeta from 'vue-meta';

axios.defaults.withCredentials = true;

Vue.use(Vuetify);
Vue.use(VueAxios, axios);
Vue.use(VueMeta);
Vue.use(VueToast);


import 'vue-toast-notification/dist/theme-default.css';
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import './registerServiceWorker'


Vue.config.productionTip = false;

var SHOP_ID = "1";
var SHOP_NAME = "BLACK_PACK";
var API_URL = "http://api.shopmaster.local/";
var AUTH_REQUIRED = false;
var LOGO_URL = "http://app.shopmaster.local/images/android-chrome-512x512.png";
var SPLASH = 1;


Vue.config.shop_id = SHOP_ID;
Vue.config.shop_name = SHOP_NAME;
Vue.config.api_url = API_URL;
Vue.config.auth_required = AUTH_REQUIRED;
Vue.config.logo_url = LOGO_URL;
Vue.config.splash = SPLASH;

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')