<template>    
  <div id="register-user"> 

    <v-app-bar dark fixed app>
      <router-link class="btn_back"
          v-if="$routerHistory.hasPrevious()"
          :to="{ path: $routerHistory.previous().path }">
          <v-icon>mdi-arrow-left</v-icon>
      </router-link>
      <v-spacer></v-spacer>
      <v-toolbar-title>{{getTitle()}}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    
    <v-form>    
      <v-container>
                
        <v-row>        
          <v-col cols="12" sm="6" class="text-xs-left">            
            <v-text-field
              v-model.trim="account.email" 
              v-bind:label="langpack.label_email_prompt" 
              outlined
              rounded-0
              single-line              
              class="form_field"              
              background-color="grey lighten-2"
              v-bind:hint="langpack.label_email_hint" 
              persistent-hint
            >
            </v-text-field>            
          </v-col>          
          <v-col cols="12" sm="6">          
          </v-col> 
        </v-row>
        
                
        <v-row>        
          <v-col cols="12" sm="6" class="text-xs-left">            
            <v-text-field
              v-model.trim="account.password" 
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              v-bind:label="langpack.label_password"              
              outlined
              rounded-0
              single-line              
              class="form_field"              
              background-color="grey lighten-2"
              v-bind:hint="langpack.label_password_under_field" 
              persistent-hint
              :type="show1 ? 'text' : 'password'"
              @click:append="show1 = !show1"              
              :rules="[rules.required_pwd, rules.min]"
            >
            </v-text-field>            
          </v-col>
          
          <v-col cols="12" sm="6">
            <v-text-field
              v-model.trim="account.password_repeat" 
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              v-bind:label="langpack.label_repeat_password"              
              outlined
              rounded-0
              single-line              
              class="form_field"              
              background-color="grey lighten-2"
              v-bind:hint="langpack.label_repeat_password_under_field" 
              persistent-hint
              :type="show2 ? 'text' : 'password'"
              @click:append="show2 = !show2"              
            >
            </v-text-field> 
          </v-col> 
        </v-row>
        
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              v-model.trim="account.fullname"              
              v-bind:label="langpack.label_name_prompt"              
              outlined
              rounded-0
              single-line              
              class="form_field"              
              background-color="grey lighten-2"              
              persistent-hint              
            >
            </v-text-field>
          </v-col>
          
          <v-col cols="12" md="4">            
            <v-card class="d-flex justify-left align-center" dark>
              <v-card class="px-3" >{{langpack.label_sex}}:</v-card>
              <v-card>
                <v-radio-group v-model="account.sex" mandatory row>
                  <v-radio v-bind:label="langpack.label_sex_male" value=1 ></v-radio>
                  <v-radio v-bind:label="langpack.label_sex_female" value=2></v-radio>
                </v-radio-group>
              </v-card>
            </v-card>
          </v-col>          
        </v-row>  
        
        <v-row>          
          <v-col cols="10" sm="4">
            <label>{{langpack.label_height}}:</label>
            <v-text-field
                v-model.number="account.height"
                append-outer-icon="mdi-plus" 
                prepend-icon="mdi-minus"
                @click:append-outer="incrementHeight"
                @click:prepend="decrementHeight"                
                single-line
                outlined
                suffix=" cm"
                rounded-0
                class="form_field"                
                background-color="grey lighten-2"
              >
            </v-text-field>
          </v-col>
          
          <v-col cols="10" sm="4">
            <label>{{langpack.label_upperbody_circ}}:</label>
            <v-text-field
                v-model.number="account.upperbody_circ"
                append-outer-icon="mdi-plus" 
                prepend-icon="mdi-minus"
                @click:append-outer="incrementUpperbodyCirc"
                @click:prepend="decrementUpperbodyCirc"
                single-line
                outlined
                suffix=" cm"
                rounded-0
                class="form_field"                
                background-color="grey lighten-2"
              >
            </v-text-field>
          </v-col>
          
          <v-col cols="10" sm="4">
            <label>{{langpack.label_abdominal_circ}}:</label>
            <v-text-field
                v-model.number="account.abdominal_circ"
                append-outer-icon="mdi-plus" 
                prepend-icon="mdi-minus"
                @click:append-outer="incrementAbdominalCirc"
                @click:prepend="decrementAbdominalCirc"
                single-line
                outlined
                suffix=" cm"
                rounded-0                
                class="form_field"                
                background-color="grey lighten-2"
              >
            </v-text-field>
          </v-col>          
        </v-row>
                        
        <v-divider dark></v-divider>
        
        <v-row>
          <v-col class="text-center">
            <v-btn @click="clickSubmit()" tile outlined large color="red">{{langpack.label_save}}</v-btn>
          </v-col>
        </v-row>
        
      </v-container>      
    </v-form>    
  </div>
</template>

<script>
  import DataProvider from '../modules/DataProvider.js';
  import UserData from '../modules/UserData.js';
  
  export default {
    name: 'RegisterUser',
    metaInfo()
    {
      return { title: this.langpack.label_register }      
    },
    components: {},
    data()
    {
      return {
        show1: false,
        show2: false,
        account: {
          email: "",
          fullname: "",
          password: "",
          password_repeat: "",
          height: 170,
          upperbody_circ: 0,
          abdominal_circ: 0,
          sex: 1          
        },
        
        langpack: {}
      }
    },
    methods:
    {
      getTitle: function()
      {
        return UserData.getIsLoggedIn() ? this.langpack.label_title_loggedin : this.langpack.label_title;
      },
      loadScreen: function()
      {
        var args = 
        {
          handler: 'user', 
          subprog: 'register', 
          command: 'screen',          
          avoid401Handling: true
        }
        
        DataProvider.post( args ).then(  ret => {
                                                  if( ret && ret.data )
                                                  {
                                                    this.langpack = ret.data;
                                                    if( UserData.getIsLoggedIn() )
                                                    {
                                                      this.langpack.label_password_under_field = this.langpack.label_password_if_needed;
                                                      this.langpack.label_repeat_password_under_field = "";
                                                    }
                                                    else
                                                    {
                                                      this.langpack.label_password_under_field = this.langpack.label_password_hint;
                                                      this.langpack.label_repeat_password_under_field = this.langpack.label_repeat_password_hint;
                                                    }                                                    
                                                  }
                                                },
                                      error => { 
                                                  console.error( JSON.stringify( error ) );
                                                }
                                      );
      },
      loadAccount()
      {
        var args = 
        {
          handler: 'user', 
          subprog: 'profile', 
          command: 'edit',          
          avoid401Handling: true
        }
        
        DataProvider.post( args ).then(  ret => {
                                                  if( ret && ret.data )
                                                  {
                                                    this.account = ret.data;                                                    
                                                  }
                                                },
                                      error => { 
                                                  console.error( JSON.stringify( error ) );
                                                }
                                      );
      },
      incrementAbdominalCirc: function()
      {
        this.account.abdominal_circ++;
      },
      decrementAbdominalCirc: function()
      {
        this.account.abdominal_circ = Math.max(0, --this.account.abdominal_circ);
      },
      
      incrementUpperbodyCirc: function()
      {
        this.account.upperbody_circ++;
      },
      decrementUpperbodyCirc: function()
      {
        this.account.upperbody_circ = Math.max(0, --this.account.upperbody_circ);
      },
      
      incrementHeight: function()
      {
        this.account.height++;
      },
      decrementHeight: function()
      {
        this.account.height = Math.max(0, --this.account.height);
      },
      
      clickSubmit: function()
      { 
        if( this.validateForm() )
        {
          this.submitData();
        }
      },
      submitData: function()
      {
        let subprog = UserData.getIsLoggedIn() ? 'profile' : 'register';
        let command = UserData.getIsLoggedIn() ? 'userdata_save' : 'proceed';
        
        var args = 
        {
          handler: 'user', 
          subprog: subprog, 
          command: command,
          email: this.account.email,
          password: this.account.password,
          fullname: this.account.fullname,
          sex: this.account.sex,
          height: this.account.height,
          upperbody_circ: this.account.upperbody_circ,
          abdominal_circ: this.account.abdominal_circ,
          avoid401Handling: true
        }
        
        DataProvider.post( args ).then(  ret => {
                                                  if( ret && ret.data )
                                                  {
                                                    if( ret.data.message )
                                                    {
                                                      this.$toast.open({
                                                        message: ret.data.message,
                                                        position: 'bottom',
                                                        type: ret.data.variant == 'success' ? 'success' : 'warning'            
                                                      });
                                                      
                                                      if( ret.data.variant == 'success' )
                                                      {
                                                        setTimeout( () => {
                                                                            let route = UserData.getIsLoggedIn() ? '/shop/catalog' : '/login';
                                                                            this.$router.push({ path: route }).catch( (err) => {console.error(err)} ); 
                                                                          }, 1000 );
                                                      }
                                                    }
                                                  }
                                                },
                                      error => { 
                                                  console.error( JSON.stringify( error ) );
                                                }
                                      );
      },
      validateForm: function()
      {
        if( !this.account.email.length )
        {
          this.$toast.open({
            message: this.message_empty_email,
            position: 'bottom',
            type: 'warning'            
          });          
          return false;
        }
        
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if( !re.test( this.account.email ) )
        {
          this.$toast.open({
            message: this.langpack.message_no_login,
            position: 'bottom',
            type: 'warning'            
          });          
          return false;
        }
        
        
        if( !UserData.getIsLoggedIn() && ( !this.account.password.length && this.account.password.length < 6 ) )
        {          
          this.$toast.open({
            message: this.langpack.message_password_too_short,
            position: 'bottom',
            type: 'warning'            
          });          
          return false;
        }
        
        if( this.account.password_repeat != this.account.password )
        {
          this.$toast.open({
            message: this.langpack.message_password_repeat_mismatch,
            position: 'bottom',
            type: 'warning'            
          }); 
          return false;
        }
        
        if( !this.account.fullname.length )
        {
          this.$toast.open({
            message: this.langpack.message_no_fullname,
            position: 'bottom',
            type: 'warning'            
          });
          return false;
        }
        return true;
      }
    },
    mounted: function()
    {
      this.loadScreen();
      if( UserData.getIsLoggedIn() )
      {
        this.loadAccount();
      }
    },
    created: function()
    {
      if( UserData.getIsLoggedIn() )
      {
        this.rules = {
                        required_pwd: true, //any value is valid
                        min: v => ( v.length == 0 || v.length >= 6 ) || this.langpack.message_password_too_short,
                      };
      }
      else
      {
        this.rules = {
                        required_pwd: value => !!value || this.langpack.message_password_invalid,
                        min: v => ( v.length >= 6 ) || this.langpack.message_password_too_short,
                      };
      }      
    }
  }
</script>

<style scoped>
  
  .btn_back
  {
    text-decoration: none;
  }
  
  #register-user
  {
    background: #000;
    height: 100%;
  }
  
  .form_field
  {
    border-radius: 0;    
  }    
    
  .form_field >>> .v-text-field__slot input {
    color: #555;    
  }
  
  .form_field >>> .v-text-field__details
  {
    padding: 0;
    margin: 0;
  }
  
  .form_field >>> .v-messages__message
  {
    color: #aaa;
  }
    
  #register-user button
  {
    border-radius: 0;
    text-transform: uppercase;
    color: #cc0f13;
    font-weight: bold;
    font-size: 1.2em;
    border-color: #fff;
    background: #000; 
  }
  
  >>>.v-input__icon.v-input__icon--prepend .v-icon
  {
    border: 1px solid #fff;
  }
    
  >>>.v-input__icon.v-input__icon--prepend .v-icon:before
  {
    color: #f00;
  }
    
  >>>.v-input__icon.v-input__icon--append-outer .v-icon
  {
    border: 1px solid #fff;
  }
  
  >>>.v-input__icon.v-input__icon--append-outer .v-icon:before
  {
    color: #f00;
  }
  
</style>