<template>    
  <div id="login-form">
    
    <v-app-bar dark fixed app>
      <router-link class="btn_home"
          v-if="homeAllowed"
          to="/shop/catalog">
          <v-icon>mdi-home</v-icon>
      </router-link>
      <v-spacer></v-spacer>
      <v-toolbar-title>{{langpack.login_title}}</v-toolbar-title>
      <v-spacer></v-spacer>      
    </v-app-bar>
    
    <div class="logo" :style="styleObject"></div>    
    <v-form>    
      <v-container>
        <v-row justify="center">
          <v-col cols="12" sm="6" class="text-center">           
            <v-text-field
              v-model.trim="email"
              v-bind:label="langpack.label_email"              
              outlined
              rounded-0
              single-line
              dense
              class="form_field"      
              color="red"
              background-color="grey lighten-2"
            >
            <v-icon slot="append" color="grey">person</v-icon>
            </v-text-field>
            
            <v-text-field
              type="password" 
              v-model.trim="password"
              v-bind:label="langpack.label_password"
              outlined
              rounded-0
              single-line
              dense
              class="form_field"      
              color="red"
              background-color="grey lighten-2"
            >
              <v-icon slot="append" color="grey">lock</v-icon>
            </v-text-field>            
          </v-col>          
        </v-row>        
        <v-row>
          <v-col class="text-center">
            <v-btn @click="submitClick()" tile outlined large color="red">{{langpack.label_login}}</v-btn>
          </v-col>
        </v-row>        
        <v-row>
          <v-col class="text-center">
            <router-link to="/register" class="white_link">{{langpack.label_register}}</router-link>            
          </v-col>
          <v-col class="text-center">
            <router-link to="/restore" class="white_link">{{langpack.label_pwd_forgot}}</router-link>            
          </v-col>
        </v-row>        
      </v-container>      
    </v-form>    
  </div>  
</template>

<script>
  import Vue from 'vue';
  import DataProvider from '../modules/DataProvider.js'
  import UserData from '../modules/UserData.js'
  
  export default {
    name: 'LoginForm',
    metaInfo() 
    {
      return { title: this.langpack.login_title }      
    },
    components: {},
    data()
    {
      return {
        email: "",
        password: "",
        langpack: {},
        homeAllowed: !Vue.config.auth_required,
        styleObject: {
                      backgroundImage : "url('" + Vue.config.logo_url + "')"
                    }
      }
    },
    methods:
    {      
      submitClick: function()
      { 
        if( this.validateForm() )
        {          
          var args = 
          {
            handler: 'user', 
            subprog: 'login', 
            command: 'proceed',
            email: this.email,
            password: this.password,            
            avoid401Handling: true
          }
          
          DataProvider.post( args ).then(  ret => {
                                                    if( ret && ret.data )
                                                    {                                                      
                                                      if( ret.data.success == 1 )
                                                      { 
                                                        UserData.setUsername( ret.data.user.email );
                                                        UserData.setAuth( ret.data.user.app_access_token );
                                                        UserData.setUserFullname( ret.data.user.fullname );
                                                        UserData.setUserID( ret.data.user.id );
                                                        UserData.setIsLoggedIn( true );
                                                      
                                                        this.$router.push({ path: '/shop/catalog' }).catch((err) => {console.error(err)});
                                                      }                                                        
                                                      else if( ret.data.message )
                                                      {
                                                        this.$toast.open({
                                                          message: ret.data.message,
                                                          position: 'bottom',
                                                          type: ret.data.variant           
                                                        });                                                      
                                                      }
                                                    }
                                                  },
                                        error => { 
                                                    console.error( JSON.stringify( error ) );
                                                  }
                                        );
        }
      },
      validateForm: function()
      {
        if( !this.email.length )
        {
          this.$toast.open({
            message: this.langpack.message_no_login,
            position: 'bottom',
            type: 'warning'
          });
          return false;
        }
        
        if( !this.password.length )
        {
          this.$toast.open({
            message: this.langpack.message_no_password,
            position: 'bottom',
            type: 'warning'
          });
          return false;
        }
        return true;
      },
      loadScreen: function()
      {
        var args = 
        {
          handler: 'user', 
          subprog: 'login', 
          command: 'screen',          
          avoid401Handling: true
        }        
        DataProvider.post( args ).then(  ret => {
                                                  if( ret && ret.data )
                                                  {
                                                    this.langpack = ret.data;                                                    
                                                  }
                                                },
                                      error => { 
                                                  console.error( JSON.stringify( error ) );
                                                }
                                      );
      },
      confirmRegistration: function( confirm_code )
      {
        var args = 
        {
          handler: 'user', 
          subprog: 'register', 
          command: 'confirm',
          confirm_code: confirm_code,          
          avoid401Handling: true
        }
        
        DataProvider.post( args ).then(  ret => {
                                                  if( ret && ret.data )
                                                  {                                                    
                                                    if( ret.data.message )
                                                    {
                                                      this.$toast.open({
                                                        message: ret.data.message,
                                                        position: 'bottom',
                                                        type: ret.data.variant
                                                      });
                                                    }
                                                  }
                                                },
                                      error => { 
                                                  console.error( JSON.stringify( error ) );
                                                }
                                      );
      }
    },
    mounted: function()
    {      
    },
    created: function()
    {   
      this.loadScreen();
      if( this.$route.params.confirm )
      {
        this.confirmRegistration( this.$route.params.confirm );
      }
    }
  }
</script>

<style scoped>
  
  .btn_home
  {
    text-decoration: none;
  }
  
  .form_field
  {
    border-radius: 0;    
  }    
    
  .form_field >>> .v-text-field__slot input {
    color: #555;
    /*text-transform: uppercase;*/
  }
  
  
  #login-form .logo
  {
    background-color: transparent;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: contain;
    height: 30vh;
    top: 20px;
  }
  
  #login-form button
  {
    border-radius: 0;
    text-transform: uppercase;
    color: #cc0f13;
    font-weight: bold;
    font-size: 1.2em;
    border-color: #fff;
    background: #000;    
  }
  
  .white_link
  {
    color: white;
  }
  
      
</style>