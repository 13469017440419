import Vue from 'vue';
import axios from 'axios';
import UserData from './UserData.js'

export default {
  name: 'DataProvider',
  post: function( params )
  {
    params.shop_id = Vue.config.shop_id;
    var headers = { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' };
    var loggedIn = UserData.getIsLoggedIn();
    if( loggedIn )
    {
      headers['Username'] = UserData.getUsername();
      headers['Auth'] = UserData.getAuth();
    }
    
    return axios.post( Vue.config.api_url, params, { headers: headers } )
                          .then( 
                                  response => {
                                                return this.handleResponse( response );
                                              })
                              .catch( error => { 
                                                  console.error(error);
                                                  return this.handleError( error, params );
                                                });
  },
  
  handleResponse: function( res )
  {
    switch( res.status )
    {
      case 200:
        return res.body || res;
    }    
  },
  
  handleError: function( error, params )
  {
    if( error.status == 401 )
    {
      if( !params.avoid401Handling )
      {
        //this.broadcaster.broadcast('user:logout', '');
      }     
    }    
    return Promise.reject( error );
  }  
}