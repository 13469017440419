<template>
  <v-sheet dark>
    <v-row class="mx-1">        
      <v-col
        cols="12"          
        sm="4"
        md="3"
        lg="2"
        v-for="(item, $index) in list" :key="$index"
      >          
        <v-card :to="'/shop/product/' + item.id">    
          <v-img height="250" :src="item.picture" contain>
            <div class="sale_label" v-if="item.sale==1">{{langpack.sale}}</div>
            <div class="new_label" v-if="item.novelty==1">{{langpack.new}}</div>
          </v-img>
          <v-card-text>      
            <v-row>
              <v-col class="pa-0">{{item.name}}</v-col>
              <v-col cols="auto" class="pa-0 text-right font-weight-bold">{{item.price}}</v-col>
            </v-row>      
          </v-card-text>
        </v-card>          
      </v-col>
    </v-row> 

    <infinite-loading @infinite="infiniteHandler">
      <div slot="spinner"></div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>    
  </v-sheet>
</template>

<script>
  import DataProvider from '../modules/DataProvider.js';
  import InfiniteLoading from 'vue-infinite-loading';
  
  export default {    
    components: {
      InfiniteLoading,
    },
    name: 'ShopSpecial',
    data ()
    {
      return {        
        title: 'Group 2',
        langpack: {},
        page: 1,
        list: []
      }
    },
    methods: 
    {
      infiniteHandler($state)
      {
        var args = 
        {
          handler: 'shop', 
          subprog: 'shop_catalog', 
          command: 'products',
          page: this.page,
          recordsPerPage: 10,
          noFolders: true,
          parentFolder: 'group2'
        }

        DataProvider.post( args ).then(  ret => {
                                                  if( ret.data.table.length > 0 )
                                                  {
                                                    this.page += 1;
                                                    this.list.push(...ret.data.table);
                                                    $state.loaded();
                                                  } 
                                                  else 
                                                  {
                                                    $state.complete();
                                                  }                                                  
                                                },
                                      error => { 
                                                  console.error( JSON.stringify( error ) );
                                                  $state.complete();
                                                }
                                      );
      },
      loadScreen()
      {
        var args = 
        {
          handler: 'shop', 
          subprog: 'shop_catalog', 
          command: 'view',
          group: 'group2'
        }
        
        DataProvider.post( args ).then(  ret => {
                                                  if( ret && ret.data )
                                                  {
                                                    this.langpack = ret.data.langpack;
                                                    if( ret.data.group )
                                                    {
                                                      this.title = ret.data.group.name;
                                                      this.$emit('subpage-loaded', this.title);
                                                    }
                                                  }
                                                },
                                      error => { 
                                                  console.error( JSON.stringify( error ) );
                                                }
                                      );
      }
    },
    created: function()
    {
      this.loadScreen();      
      this.$emit('subpage-loaded', this.title);      
    }    
  }
</script>

<style scoped>
  .sale_label
  {
    position: absolute;
    right: 0;
    bottom: 10%;
    background: #f00;
    padding: 5px;
    color: #fff;
    text-transform: uppercase;
  }
  
  .new_label
  {
    position: absolute;
    left: 0;
    bottom: 10%;
    background: #0c0;
    padding: 5px;
    color: #fff;
    text-transform: uppercase;
  }
</style>