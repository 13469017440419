<template>    
  <v-card dark>  
    <v-carousel height="66vh">
      <v-carousel-item
        v-for="picture in product.pictures"  
        :key="picture.id"
      >
        <v-sheet
          height="100%"
          tile
        >
          <v-row
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-img :src="picture.picture"></v-img>
          </v-row>          
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
    
    <v-card-text>      
      <v-row>
        <v-col class="pa-0">
          <h2>{{product.name}}</h2>
          <p>{{product.descr}}</p>
        </v-col>
        
        <v-col cols="auto" class="pa-0 text-right">          
          <v-card
            class="d-flex align-end"            
            flat
            height="50"
            tile
          >            
            <v-btn   
              class="addbasket_float"
              color="red"
              fab
              dark                
              absolute
              bottom
              right
              @click="addToCart()"
            >
              <v-icon>mdi-cart-plus</v-icon>
            </v-btn>            
            
            <h3>{{order.price}}</h3>            
          </v-card>          
          <small>inkl. MwSt.</small>
        </v-col>        
      </v-row>
     
      <v-row v-for="option in filterOptions(product.options, CONTROLTYPE_COLORLIST)" :key="option.id">
        <v-col cols="auto" class="pa-2 py-1">{{option.caption}}:</v-col>
        <v-col class="pa-1">
          <ul class="color_radioset">
            <li class="color_radio" @change="changeOption('option_'+option.id, item.key)" v-for="item in option.values" :key="item.key">
              <input type="radio" :id="'option_'+option.id+'_'+item.key" :name="'option_'+option.id" :value="item.key" :checked="item.key==1" />
              <label :for="'option_'+option.id+'_'+item.key" v-bind:style="{ 'background-color': item.color, 'outline-color': item.color}"></label>
            </li>
          </ul>
        </v-col>
      </v-row>
      
      <v-row v-for="option in filterOptions(product.options, CONTROLTYPE_RADIOLIST)" :key="option.id">
        <v-col cols="auto" class="pa-2 py-1">{{option.caption}}:</v-col>
        <v-col class="pa-1">
          <ul class="special_radioset">
            <li class="special_radio" @change="changeOption('option_'+option.id, item.key)" v-for="item in option.values" :key="item.key">
              <input type="radio" :id="'option_'+option.id+'_'+item.key" :name="'option_'+option.id" :value="item.key" :checked="item.key==1" />
              <label :for="'option_'+option.id+'_'+item.key">{{item.name}}</label>
            </li>
          </ul>
        </v-col>
      </v-row>
      
      <v-row v-for="option in filterOptions(product.options, CONTROLTYPE_SELECT)" :key="option.id">
        <v-col cols="auto" class="pa-1 py-1"><v-subheader class="pa-1">{{option.caption}}:</v-subheader></v-col>
        <v-col class="pa-1">
          <v-select
            :items="option.values"
            item-text="name"
            item-value="key"
            :value="key"
            @input="changeOption('option_'+option.id, $event)"
            solo
          ></v-select>
        </v-col>
      </v-row>
      
      <v-row v-for="option in filterOptions(product.options, CONTROLTYPE_CHECKBOX)" :key="option.id">
        <v-col cols="auto" class="pa-1 py-1"><v-subheader class="pa-1">{{option.caption}}:</v-subheader></v-col>
        <v-col class="pa-1">
          <v-checkbox
            v-for="item in option.values"
            :key="item.key"
            :value="item.key"
            :label="item.name"            
            dense
            class="shrink my-2"
            hide-details
            @change="changeCheckbox('option_'+option.id, item.key, $event)"
          >
          </v-checkbox>
        </v-col>
      </v-row>      
    </v-card-text>    
  </v-card>
</template>

<script>

  import DataProvider from '../modules/DataProvider.js';
  import UserData from '../modules/UserData.js';
  
  export default {    
    name: 'ShopProduct',
    props: ['id'],
    data ()
    {
      return {        
        title: 'ShopProduct',
        product: { pictures: [], price: 0, options: [] },
        order: { product_id: 0, options: {}, price: 0 }
      }
    },
    methods: 
    {
      changeOption(id, val)
      {        
        this.order.options[id] = val;
        this.setPrice();
      },
      changeCheckbox(id, idx, checked)
      {
        if( checked && !this.order.options[id].includes(idx) )
        {
          this.order.options[id].push(idx);
          this.setPrice();
        }
        else if( !checked && this.order.options[id].includes(idx) )
        {
          const index = this.order.options[id].indexOf(idx);
          if (index > -1) 
          {
            this.order.options[id].splice(index, 1);
            this.setPrice();
          }
        }
      },
      setPrice()
      {
        var price = parseFloat( this.product.price );
        for( var i in this.product.options )
        {
          var corr_price = 0;
          //i=id abstr option          
          if( this.product.options[i].datatype_id == 2 ) //checkboxes
          {
            for( var n in this.product.options[i].values )
            {              
              if( this.order.options[i] )
              {
                if( this.order.options[i].includes( this.product.options[i].values[n].key ) )
                {
                  corr_price = parseFloat( this.product.options[i].values[n].price ? this.product.options[i].values[n].price : 0 );
                  price += corr_price;
                }
              }
            }
          }
          else
          {
            corr_price = parseFloat( 
                                      ( this.product.options[i].values[this.order.options[i]] && this.product.options[i].values[this.order.options[i]].price ) ? 
                                      this.product.options[i].values[this.order.options[i]].price : 
                                      0 
                                    );
            price += corr_price;
          }          
        }
        this.order.price = price.toFixed(2);        
      },
      filterOptions( arr, datatype_id )
      {
        var ret = [];
        for( var i in arr )
        {
          if( arr[i].datatype_id == datatype_id )
          {
            ret.push( arr[i] );
          }
        }
        return ret;
      },
      addToCart()
      {
        this.setPrice();
        
        var args = this.order;
        args.handler = 'order';
        args.subprog = 'item';
        args.command = 'insert';
        args.options = this.order.options;
        
        var customer_token = UserData.getShopToken();
        if( customer_token )
        {
          args.customer_token = customer_token;
        }
                  
        DataProvider.post( args ).then(  ret => {
                                                  if(ret && typeof(ret.data) != 'undefined')
                                                  {                                                    
                                                    this.$emit('cart-changed', ret.data);
                                                  }
                                                },
                                        error => { 
                                                    console.error( JSON.stringify( error ) );
                                                  }
                                      );
      },
      loadProduct()
      {
        var args = 
        {
          handler: 'shop', 
          subprog: 'shop_catalog', 
          command: 'product',
          id: this.id
        }
        
        DataProvider.post( args ).then(  ret => {
                                                  if( ret && ret.data )
                                                  {
                                                    this.product = ret.data;
                                                    this.order.product_id = this.product.id
                                                    this.order.price = this.product.price;
                                                    for( var i in this.product.options )
                                                    {
                                                      if( this.product.options[i].datatype_id == this.CONTROLTYPE_CHECKBOX )
                                                      {
                                                        this.changeOption(i, []);
                                                      }
                                                      else
                                                      {
                                                        this.changeOption(i, 1);
                                                      }                                                      
                                                    }
                                                    this.setPrice();                                                    
                                                  }
                                                },
                                        error => { 
                                                    console.error( JSON.stringify( error ) );
                                                  }
                                      );
      }
    },
    created: function()
    {      
      //this.$emit('subpage-loaded', this.title);
      this.CONTROLTYPE_COLORLIST = 7;
      this.CONTROLTYPE_RADIOLIST = 5;
      this.CONTROLTYPE_CHECKBOX = 2;
      this.CONTROLTYPE_SELECT = 6;
      this.loadProduct();
    }    
  }
</script>

<style scoped>
  .addbasket_float
  {
    transform: translateY(-60px);
  }
  
  ul.special_radioset
  {
    padding: 0;
  }
  ul.special_radioset li:not(:first-child):before
  {
    content: '|';
    margin: 0 3px;
    padding: 0 3px;
  }
  .special_radio
  {
    display: inline-block;
  } 
  .special_radio input[type="radio"] 
  {
    display: none;    
  }
  .special_radio label
  {
    cursor: pointer; 
    font-size: 1.1em;
  }  
  .special_radio input[type="radio"]:checked + label
  {
    color: #f00;    
  }
  /**********/
  
  ul.color_radioset
  {
    padding: 0;
  }
  
  .color_radio
  {
    display: inline-block;
  } 
  .color_radio input[type="radio"] 
  {
    display: none;    
  }
  .color_radio label
  {
    cursor: pointer;
    display: block;
    width: 20px;
    height: 20px;
    border: #aaa 1px solid;
    margin: 0 4px;    
  }  
  .color_radio input[type="radio"]:checked + label
  {
    outline: 1px solid;
    outline-offset: 2px;
  }
</style>