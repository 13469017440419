export default {
  name: 'UserData',
  getIsLoggedIn: function () 
  {
    let value = window.localStorage.getItem( 'blackpack_loggedIn' );
    let ret = ( value == null || typeof( value ) == 'undefined' ) ? false : !!value;    
    return ret;
  },
  setIsLoggedIn: function (value) 
  {
    window.localStorage.setItem( 'blackpack_loggedIn', value );
  },
  
  getShopToken: function()
  {
    return window.localStorage.getItem( 'blackpack_ShopToken' );
  },    
  setShopToken: function( value )
  {
    window.localStorage.setItem( 'blackpack_ShopToken', value );
  },
  
  getUsername: function()
  {
    return window.localStorage.getItem( 'blackpack_Username' );
  },    
  setUsername: function( value )
  {
    window.localStorage.setItem( 'blackpack_Username', value );
  },
  
  getAuth: function()
  {
    return window.localStorage.getItem( 'blackpack_Auth' );
  },  
  setAuth: function( value )
  {
    window.localStorage.setItem( 'blackpack_Auth', value );
  },
  getUserFullname: function()
  {
    return window.localStorage.getItem( 'blackpack_UserFullname' );
  },  
  setUserFullname: function( value )
  {
    window.localStorage.setItem( 'blackpack_UserFullname', value );
  },
  getUserID: function()
  {
    return window.localStorage.getItem( 'blackpack_UserID' );
  },  
  setUserID: function( value )
  {
    window.localStorage.setItem( 'blackpack_UserID', value );
  },
  logOut: function()
  {
    window.localStorage.removeItem( 'blackpack_loggedIn' ); 
    window.localStorage.removeItem( 'blackpack_Username' ); 
    window.localStorage.removeItem( 'blackpack_Auth' ); 
    window.localStorage.removeItem( 'blackpack_UserFullname' ); 
    window.localStorage.removeItem( 'blackpack_UserID' ); 
  }
}