<template>
  <div>
    <pull-to :top-load-method="refresh" :top-config="pullConfig" v-if="items.length>0">
      <v-card
        class="mx-auto"
        dense    
      >    
        <v-list-item v-for="(item, $index) in items" :key="$index">          
          <!--          
          -->          
          <v-list-item-content>            
            <v-list-item-title>{{item.order_header}}</v-list-item-title>
            <v-list-item-subtitle v-html="item.order_details"></v-list-item-subtitle>            
            <v-row class="mx-1 py-3">          
              <v-col class="px-1 py-0"
                    cols="9"                   
                  >                
                <div class="status_label"><v-icon>mdi-information</v-icon><span v-html="item.status_label"></span></div>
              </v-col>
              <v-col class="px-1 py-0 text-center"
                    cols="3">            
                  <v-list-item-title class="text-right">{{item.amount}}</v-list-item-title>
              </v-col>          
            </v-row>            
          </v-list-item-content>          
        </v-list-item>
      </v-card> 
    </pull-to>
    
    <template>
      <div class="basket_empty" v-if="items.length==0">					
        {{langpack.no_actual_orders}}
      </div>
    </template>
  
  </div>
</template>

<script>
  import DataProvider from '../modules/DataProvider.js';
  import UserData from '../modules/UserData.js';
  import PullTo from 'vue-pull-to';

  export default {
    name: 'ShopDeliver',
    components: {
      PullTo
    },
    data ()
    {
      return {        
        title: 'Bestellstatus',
        langpack: {},
        items: [],
        pullConfig: {
                      pullText: 'pullText', // The text is displayed when you pull down
                      triggerText: 'triggerText', // The text that appears when the trigger distance is pulled down
                      loadingText: 'loadingText', // The text in the load
                      doneText: 'doneText', // Load the finished text
                      failText: 'failText', // Load failed text
                      loadedStayTime: 400, // Time to stay after loading ms
                      stayDistance: 50, // Trigger the distance after the refresh
                      triggerDistance: 70 // Pull down the trigger to trigger the distance
                    }
      }
    },
    methods:
    {
      refresh(loaded)
      {
        this.loadData(loaded);
      },
      loadData(loaded)
      {
        var customer_token = UserData.getShopToken();
        if( customer_token )
        {
          var args = 
          {
            handler: 'order',
            subprog: 'orders_data'
          }
          
          DataProvider.post( args ).then(  ret => {
                                                    if( ret.data )
                                                    {
                                                      this.items = ret.data.list;
                                                      this.$emit('orders-changed', this.items.length);
                                                                                                            
                                                      if( loaded )
                                                      {
                                                        loaded('done');
                                                      }
                                                      else
                                                      {   
                                                        this.langpack = ret.data.langpack;
                                                        this.pullConfig = {...this.pullConfig, ...ret.data.langpack.pullConfig};
                                                      }
                                                    }                                                                                                    
                                                  },
                                        error => { 
                                                    console.error( JSON.stringify( error ) );                                                    
                                                  }
                                        );
        }
      }
    },
    
    created: function()
    {
      this.$emit('subpage-loaded', this.title);
      if( UserData.getIsLoggedIn() )
      {
        this.loadData();
      }
      else
      {
        this.$router.push({ path: '/login' });
      }
    }    
  }
</script>

<style scoped>

  .basket_empty
  {
    text-align: center;
    padding: 2rem 0;
  }
  
  >>> .v-list-item__subtitle .product_name
  {
    font-weight: bold;
    font-size: 1.1em;
    margin-top: 6px;
    margin-bottom: 0;
  }
  
  >>> .v-list-item__subtitle .options_text
  {
    font-size: 0.9em;
    font-style: italic;
    margin-bottom: 0;
  }
  
  >>> .v-list-item__subtitle .price
  {
    /*font-weight: bold;*/
    margin-bottom: 0;
  }
  
  .status_label
  {
    font-size: 0.9em;
  }
  
</style>