<template>
  
  <v-app>
    
    <v-banner v-if="deferredPrompt"
        app
        sticky
        color="secondary"        
        dark                
        class="text-left"
        :style="bannerStyle"
      >
      
      
        <v-row>
          <v-col cols="auto">
          <div class="install-logo" :style="styleObject"></div>
          </v-col>
          <v-col>
            <p>{{langpack.install_prompt}}</p>
          </v-col>
        </v-row>
      
      
      
      <template v-slot:actions>
        <v-btn text @click="dismiss">{{langpack.cancel}}</v-btn>
        <v-btn text @click="install">{{langpack.install}}</v-btn>
      </template>
      
    </v-banner>
    
    <PWAPrompt />
    
    <v-main>
      <transition name="slide-fade" mode="out-in">
        <router-view /> 
      </transition>
      <div v-if="loading" class="splash-screen"><div class="splash-logo" :style="styleObject"></div></div>  
    </v-main>
  </v-app>
</template>

<script>

import Vue from 'vue';
import UserData from './modules/UserData.js'
import DataProvider from './modules/DataProvider.js';
import PWAPrompt from "vue2-ios-pwa-prompt";

export default {
  name: 'App',
  components: { PWAPrompt },
  metaInfo()
  {
    return {
              titleTemplate: `%s | ${Vue.config.shop_name}`,
              meta: [
                      { charset: 'utf-8' },          
                      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
                    ] 
            }
  },  
  data()
  {
    return {  
      deferredPrompt: null,
      langpack: {},
      loading: Vue.config.splash,      
      styleObject: {
                      backgroundImage : "url('" + Vue.config.logo_url + "')"
                    },
      bannerStyle: {
                      position: 'fixed',
                      zIndex: 9999
                    }
    }
  },
  methods:
  {    
    gotoShop: function()
    {
      var customer_token = UserData.getShopToken();
      if( customer_token )
      {
        var args = 
        {
          handler: 'order', 
          subprog: 'count',                    
          avoid401Handling: true,
          customer_token: customer_token
        }
        
        DataProvider.post( args ).then(  ret => {                                              
                                                  if( ret && typeof(ret.data) != 'undefined' )
                                                  {                                                    
                                                    if( ret.data > 0 )
                                                    {
                                                      this.$router.push({ path: '/shop/basket' });
                                                    }
                                                    else
                                                    {
                                                      this.$router.push({ path: '/shop/catalog' });
                                                    }
                                                  }
                                                },
                                          error => {
                                                      console.error( JSON.stringify( error ) );
                                                    }
                                      );
      }
      else
      {
        this.$router.push({ path: '/shop/catalog' });
      }
    },
    afterSplash: function()
    {
      if( Vue.config.auth_required )
      {
        if( UserData.getIsLoggedIn() )
        {
          this.gotoShop();          
        }
        else
        {        
          this.$router.push({ path: '/login' });
        }
      }
      else
      {
        this.gotoShop();        
      }
    },
    loadScreen: function()
    {
      let _this = this;
      
      var args = 
      {
        handler: 'assist', 
        subprog: 'get_general_langpack',
        avoid401Handling: true
      }

      DataProvider.post( args ).then(  ret => {
                                                if( ret && ret.data )
                                                {
                                                  _this.langpack = ret.data;
                                                }
                                              },
                                    error => { 
                                                console.error( JSON.stringify( error ) );
                                              }
                                    );
    },
    async dismiss() 
    {
      this.deferredPrompt = null;
    },
    async install() 
    {
      this.deferredPrompt.prompt();
    }
  },  
  created: function()
  {
    let _this = this;
    _this.loadScreen();
    
    window.addEventListener( "beforeinstallprompt", e => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
    window.addEventListener( "appinstalled", () => {
      this.deferredPrompt = null;
    });    
    
    if( Vue.config.splash )
    {
      setTimeout(() => {
      
        _this.loading = false;        
        _this.afterSplash();
      }, 2500 );      
    }
    else
    {
      _this.afterSplash();
    }    
  }
}

</script>

<style>
  html, body
  {    
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;    
  }
  
  #app 
  {
    background: #000;
    color: #fff;
  }

  .splash-screen
  {
    position: absolute; 
    width: 100vw; 
    height: 100vh; 
    top: 0; 
    left: 0;
    z-index: 10000; 
    background-color: #000;    
  }
  
  .install-logo
  {
    width: 48px; 
    height: 48px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: contain;
  }
  
  .splash-logo
  {
    position: relative;
    z-index: 10001;
    width: 100%; 
    height: 100%; 
    /*top: 50%; 
    left: 50%;*/
    /*transform: translate(-50%, -50%);*/
    transform: scale(1);
    opacity: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: contain;
    animation: crescendo 2.5s ease-out;
  }
  
  @keyframes crescendo 
  {  
    0% {opacity: 1; transform: scale(0);}
    35% {opacity: 1; transform: scale(1);}
    75% {opacity: 1; transform: scale(1);}
    100% {opacity: 0;}
  }

</style>
